import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import useForm from 'react-hook-form';
import NavBar from "./NavBar";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import {Typography} from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import start_build from "../helpers/sow_builder";

const customStyles = {
    control: base => ({
        ...base,
        height: 50,
        minHeight: 50
    })
};

/*<-------------------------------- This is for loading animation ----------------------------------->*/
const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

/*<-------------------------------- This is the form react prop ------------------------------------->*/
const Form  =  () => {
    const [ignwUser, setIgnwUser] = useState(false); //checkProfile() sets this to true if google account is ignw.io
    const [userEmail, setUserEmail] = useState(null);
    const [name, setName] = useState(null);
    const [formState, setFormState] = useState(null);
    const [template, setTemplate] = useState(null);
    const [tnc, setTnc] = useState(null);
    const [copperError, setCopperError] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [change, setChange] = useState(false);
    const [change2, setChange2] = useState(false);
    const {register, unregister, handleSubmit, errors, setValue} = useForm();
    const [copperReady, setCopperReady] = useState(false);
    const [copperInfo, setCopperInfo] = useState(null);
    const [opportunities, setOpportunities] = useState(null);
    const [links, setLinks] = useState(null);
    const [selectedOpportunity, setSelectedOpportunity] = useState(null);


    const classes = useStyles();

    /*<---------------This stuff is for the async loading autocomplete customer text box----------------->*/

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;
    const [copperList, setCopperList] = useState([]);

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const copperList = await getCopperList();

            if (active) {
                setOptions(copperList);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);


    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const list = class {
        constructor(first, last, array, count) {
            this.first = first;
            this.last = last;
            this.array = array;
            this.count = count;
        }
    };



    async function getCopperList() {
        if(!copperReady) {
            if (!copperList.length) {
                // if(!change)
                //     setChange(true);
                let url = "https://us-central1-sow-gen.cloudfunctions.net/get-companies-copper";
                let headers = {
                    'Content-Type': 'application/json'
                };
                let fullList = [];
                let i = 1;
                while (i) {
                    let company_list = {"first": '', "last": '', "array": '', "count": '', "index": i, "error": 0};
                    let response = await fetch(url, {
                        method: 'POST',
                        body: JSON.stringify(company_list), // json.dumps(data)
                        headers: headers
                    }).then(res => res.json());
                    if (response && !response.error) {
                        let len = response.array.length;
                        let tempList = response.array;
                        let x = 0;
                        while (x < len) {
                            fullList.push({name: tempList[x].name, details: tempList[x]});
                            ++x;
                        }
                        ++i;
                    } else
                        break
                }
                // if(change)
                //     setChange(false);
                return new Promise(resolve => {
                    setCopperList(fullList);
                    resolve(fullList);
                })
            } else {
                return new Promise(resolve => {
                    resolve(copperList);
                })
            }
        }
    }

    function parse_address(address_data) {
        let address = "";
        if(address_data) {
            if (address_data.street !== null)
                address += address_data["street"] + " ";
            else
                address += "";
            if (address_data["city"] !== null)
                address += address_data["city"] + ", ";
            else
                address += "";
            if (address_data["state"] !== null)
                address += address_data["state"] + ". ";
            else
                address += "";
            if (address_data["postal_code"] !== null)
                address += address_data["postal_code"] + " ";
            else
                address += "";
            if (address_data["country"] !== null)
                address += address_data["country"] + ". ";
            else
                address += "";
            return address
        }
        else return "no address in copper";
    }

    async function select(newValue) {
        if(newValue) {
            let name = newValue.name;
            newValue = newValue.details;
            let url = 'no website in copper';
            if(newValue.websites && newValue.websites[0])
                url = newValue.websites[0].url;
            let company = {
                name: name,
                background: newValue.details,
                address: parse_address(newValue.address),
                website: url,
                id: newValue.id
            };
            await setCopperInfo(company);
        }
    }

    /*<------------This checks if the user is logged in using an IGNW google account ---------------> */

    async function checkProfile() {
        function wrapCheck() {
            return new Promise((resolve, reject) => {
                window.gapi.load('auth2,signin2', function () {
                    var auth2 = window.gapi.auth2.init();
                    auth2.then(function () {
                        // Current values
                        if(auth2.isSignedIn.get()) {
                            var currentUser = auth2.currentUser.get();

                            let email = String(currentUser.getBasicProfile().getEmail());
                            let name = String(currentUser.getBasicProfile().getName());
                            setName(name);
                            setUserEmail(email);
                            resolve(email.endsWith('ignw.io'))
                        }
                        else
                            resolve(false);
                    });
                });
            });
        }
        let x = await wrapCheck();
        if(x) setIgnwUser(true);
    }

    useEffect(() => {
        checkProfile();
    }, []);

    /*<------------This stuff gets the  */
    const [openOpp, setOpenOpp] = useState(false);
    const [optionsOpp, setOptionsOpp] = useState([]);
    const loadingOpp = openOpp && optionsOpp && optionsOpp.length === 0;

    useEffect(() => {
        let active = true;

        if (!loadingOpp) {
            return undefined;
        }

        (async () => {
            if(copperInfo) {
                const oppList = await getOpportunities();



                if (active) {
                    setOptionsOpp(oppList);
                }
            }
        })();

        return () => {
            active = false;
        };
    }, [loadingOpp]);

    useEffect(() => {
        if (!openOpp) {
            setOptionsOpp([]);
        }
    }, [openOpp]);

        async function getOpportunities() {
            let company = copperInfo;
            if(company.background) {
                //setChange(true);
                const url = "https://us-central1-sow-gen.cloudfunctions.net/opportunities-copper";
                let headers = {
                    'Content-Type': 'application/json'
                };
                let opportunities = {"id": company.id, "opportunities": '', "error": 0};
                let response = await fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(opportunities), // json.dumps(data)
                    headers: headers
                }).then(res => res.json());
                let i = 0;
                if(response && response.length)
                    i = response.length - 1;
                let oppList = [];
                let blank = {
                    "name": "Other Opportunity",
                    "details": "",
                    "contact": ""
                };
                oppList.push(blank);
                while (i >= 0) {
                    if(response && response[i]) {
                        let temp = {
                            "name": response[i].name,
                            "details": response[i].details,
                            "contact": response[i].primary_contact_id
                        };
                        oppList.push(temp);
                        --i;
                    }
                }
                setOpportunities(oppList);
                //setChange(false);
                return new Promise(resolve => {
                    resolve(oppList);
                })
            }
        }


    async function getContact(id) {
        const url = "https://us-central1-sow-gen.cloudfunctions.net/contact-copper";

        let headers = {
            'Content-Type': 'application/json'
        };
        let contact = {"id": id, "email" : '', "error": 0};
        let response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(contact), // json.dumps(data)
            headers: headers
        }).then(res => res.json());
        if(response && response.email) return response.email;
    }

    useEffect(() => {
        register({name: "template"});
        register({name: "tnc"});
        return () => {
            unregister("template");
            unregister("tnc");
        }
    }, []);


    const onSubmit = (data) => {
        setFormState(data);
    };

    useEffect( () => {
        if(formState) {
            formState.email = userEmail;
            console.log(formState);
            submit();
        }


    }, [formState]);

    useEffect(() => {

    });

    async function wrapStart() {
        const url = "https://us-central1-sow-gen.cloudfunctions.net/sow-gen-backend";

        let headers = {
            'Content-Type': 'application/json'
        };
        formState.email = userEmail;
        let payload = formState;
        let response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload), // json.dumps(data)
            headers: headers
        }).then(res => res.json());

        return new Promise((resolve, reject) => {
            setLinks(response);
            resolve(response);
        });
    }

    async function submit() {
        if(formState && formState.customer) {
            setWaiting(true);

            let links = await wrapStart();
            setWaiting(false);
        }
    }

    const DriveButton = () => {

        if(links) {
            return (
                //this button becomes clickable when the SOW has been created, it opens a link to the SOW folder
                <div>
                    <Button variant="contained" style={{margin: 20}} onClick={() => {
                        newTab(links.sow)
                    }}>
                        open Sow
                    </Button>
                    <Button variant="contained" style={{margin: 20}} onClick={() => {
                        newTab(links.costing_sheet)
                    }}>
                        open costing worksheet
                    </Button>
                </div>

            )
        }

    };

    async function oppSelect(newValue) {
        setWaiting(true);
        let temp = {
            "name": newValue.name,
            "details": newValue.details,
            "contact": await getContact(newValue.contact)
        };
        await setSelectedOpportunity(temp);
        setWaiting(false);

    }

    function newTab(url) {
        window.open(url, '_blank');
    }

        return (
            <Grid container xl={10} lg={10} md={10} sm={10} xs={10} >
                <Grid>
                <Backdrop
                    className={classes.backdrop}
                    open={waiting}
                    onClick={() => {
                        setWaiting(false);
                    }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <NavBar/>
                <Paper style={{padding:40, }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Grid container spacing={2}>
                                {((ignwUser) ?
                                <Grid item xs={6}>
                                    <TextField
                                        name={'name'}
                                        defaultValue={name}
                                        error={!!errors.name}
                                        helperText={(errors.name) ? 'Author is required' : null}
                                        inputRef={register({required: true})}
                                        label="SoW Author"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                    :
                                    null
                                )}
                                <Grid item xs={6}>
                                <Grid container justify={'flex-start'}>

                                    <Button
                                        size="medium"
                                        className="g-signin2"
                                        data-onsuccess="onSignIn"
                                        fullWidth
                                    >
                                    </Button>
                                    <Typography align={"center"} variant={"body2"}>Verify you are signed into an IGNW google account</Typography>
                                    {((!ignwUser) ?
                                            <Typography align={"center"} variant={"body2"}>Once you sign in, refresh the page</Typography>
                                            :
                                            null
                                    )}

                                </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container spacing={10}>
                                <Grid item xs={6}>
                                    {((ignwUser) ?
                                        <Autocomplete
                                            id="customer"
                                            style={{ width: 300 }}
                                            open={open}
                                            onOpen={() => {
                                                setOpen(true);
                                            }}
                                            onClose={() => {
                                                setOpen(false);
                                            }}
                                            getOptionSelected={(option, value) => option.name === value.name}
                                            getOptionLabel={option => option.name}
                                            options={options}
                                            onChange={async (event, newValue) => {
                                                await setChange(true);
                                                await select(newValue);
                                                await setChange(false);

                                            }}
                                            loading={loading}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    name={'customer'}
                                                    error={!!errors.customer}
                                                    helperText={(errors.customer) ? 'Customer is required' : null}
                                                    inputRef={register({required: true})}
                                                    label="Customer"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    :
                                        null
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            {((copperInfo && !change) ?
                                    <div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    name={'customer_address'}
                                                    defaultValue={copperInfo.address}
                                                    error={!!errors.customer_address}
                                                    helperText="Customer Address"
                                                    inputRef={register({required: true})}
                                                    margin="normal"
                                                    variant="outlined"
                                                    multiline={true}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container justify={'flex-start'}>
                                                    <TextField
                                                        name={'customer_website'}
                                                        defaultValue={copperInfo.website}
                                                        error={!!errors.customer_website}
                                                        helperText="Customer Website"
                                                        inputRef={register({required: true})}
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                :
                                    null
                            )}
                        </div>
                        <div>
                            {((copperInfo && !change) ?
                                    <div>
                                        <TextField
                                            name={'customer_background'}
                                            defaultValue={copperInfo.background}
                                            error={!!errors.customer_background}
                                            inputRef={register({required: true})}
                                            helperText="Customer Background"
                                            margin="normal"
                                            variant="outlined"
                                            multiline={true}
                                            fullWidth
                                        />
                                    </div>
                            :
                                    null
                            )}
                            <div>
                                <Grid>
                                        {((copperInfo && !change) ?
                                                <Autocomplete
                                                    id="opportunity"
                                                    style={{ width: 500 }}
                                                    open={openOpp}
                                                    onOpen={() => {
                                                        setOpenOpp(true);
                                                    }}
                                                    onClose={() => {
                                                        setOpenOpp(false);
                                                    }}
                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                    getOptionLabel={option => option.name}
                                                    options={optionsOpp}
                                                    onChange={async (event, newValue) => {
                                                        if(newValue) {
                                                            await setChange2(true);
                                                            let temp = await oppSelect(newValue);
                                                            await setChange2(false);

                                                        }
                                                    }}
                                                    loading={loading}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            name={'engagement'}
                                                            error={!!errors.engagement}
                                                            helperText={(errors.engagement) ? 'Project Engagement is required' : null}
                                                            label="Project Engagement"
                                                            multiline={true}
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {loadingOpp ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />

                                                :
                                                null
                                        )}
                                </Grid>
                                <Grid container spacing={10}>
                                    <Grid item xs={6}>
                                    {((selectedOpportunity && !change2) ?
                                                <TextField
                                                    name={'customer_email'}
                                                    defaultValue={selectedOpportunity.contact}
                                                    error={!!errors.customer_email}
                                                    helperText="Customer Contact Email"
                                                    inputRef={register({required: true})}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            :
                                            null
                                    )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {((selectedOpportunity && !change2) ?
                                                    <TextField
                                                        name={'project_title'}
                                                        defaultValue={selectedOpportunity.name}
                                                        error={!!errors.customer_email}
                                                        helperText="Project Title"
                                                        inputRef={register({required: true})}
                                                        margin="normal"
                                                        variant="outlined"
                                                        multiline
                                                        fullWidth
                                                    />
                                                :
                                                null
                                        )}

                                    </Grid>
                                </Grid>

                            </div>
                            {((selectedOpportunity && !change2) ?
                                    <TextField
                                        name={'scope_description'}
                                        defaultValue={selectedOpportunity.details}
                                        error={!!errors.scope_description}
                                        helperText="Scope Summary"
                                        inputRef={register({required: true})}
                                        multiline
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                    />
                                :
                                    null
                            )}


                        </div>
                        <div>
                            <Grid container spacing={10}>
                            {((copperInfo) ?
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth>
                                        <InputLabel>Template</InputLabel>
                                        <Select
                                            name={'template'}
                                            value={template}
                                            onChange={(evt) => {
                                                setValue('template', evt.target.value);
                                                setTemplate(evt.target.value)
                                            }}
                                            labelWidth={500}
                                            styles={customStyles}
                                            fullWidth={true}
                                        >
                                            <MenuItem value="tandm_sow_template">SOW - T&M</MenuItem>
                                            <MenuItem value="fixed_sow_template">SOW - Fixed</MenuItem>
                                            <MenuItem value="bot_sow_template">SOW - Bucket of Time</MenuItem>
                                            <MenuItem value="aci_supercharger_dsi">aci_supercharger_dsi</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                :
                                    null
                            )}
                            {((copperInfo) ?
                                <Grid item xs={6}>
                                    <FormControl
                                    fullWidth>
                                        <InputLabel>Terms and Conditions</InputLabel>
                                        <Select
                                            name={'tnc'}
                                            value={tnc}
                                            onChange={(evt) => {
                                                setValue('tnc', evt.target.value);
                                                setTnc(evt.target.value)
                                            }}
                                            labelWidth={500}
                                            styles={customStyles}
                                            fullWidth={true}
                                        >
                                        <MenuItem value="tnc">Terms and Conditions</MenuItem>
                                        <MenuItem value="msa">MSA</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>
                                :
                                null
                            )}
                            </Grid>
                            {((template && template === "fixed_sow_template") ?
                                        <TextField
                                            name={'phase_one'}
                                            error={!!errors.phase_one}
                                            helperText={(errors.phase_one) ? 'Project Phase One Description is required' : null}
                                            inputRef={register({})}
                                            label="Project Phase One Description"
                                            multiline
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    :
                                    null
                            )}
                        </div>

                        <Grid item xs={12}>
                            <Grid container justify="center" >

                                <Grid key='0' item>
                                    <Grid>
                                        <Button variant="contained" type={"submit"} style={{margin:20}}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                                {((links) ?
                                        <Grid key='1' item>
                                            <Grid>
                                                <DriveButton/>
                                            </Grid>
                                        </Grid>
                                        :
                                        null
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                </Grid>
            </Grid>
    )
};


export default Form
