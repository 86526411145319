import React, {Component, useEffect, useState} from 'react'
import NavBar from "./NavBar";
import Grid from "@material-ui/core/Grid";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import config from "../config"
import useForm from 'react-hook-form';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import getCopperInfo from "../helpers/copper";

const customStyles = {
    control: base => ({
        ...base,
        height: 50,
        minHeight: 50
    })
};

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const BotForm = () => {
    const [formState, setFormState] = useState(null);
    const {register, unregister, handleSubmit, errors, setValue} = useForm();

    const [waiting, setWaiting] = useState(false);
    const [tab, setTab] = useState(true);

    const [customer, setCustomer] = useState(null);
    const [template, setTemplate] = useState(null);
    const [copperError, setCopperError] = useState(null);
    const [aboutCustomer, setAboutCustomer] = useState(null);

    const [loggedIn, setLoggedIn] = useState(false);


    const classes = useStyles();

    const onSubmit = (data) => {
        setFormState(data);
    };

    async function newCostingSheet() {
        if(tab === true) {
            setWaiting(true);
            await componentDidMount();
            let sheetID = '';
            sheetID = await copySheet(customer, template);
            const url = 'https://docs.google.com/spreadsheets/d/'+ String(sheetID) +'/edit#gid=411467044';
            window.open(url, '_blank');
            setTab(false);
            setWaiting(false);

        }
    }

    async function checkProfile() {
        function wrapCheck() {
            return new Promise((resolve, reject) => {
                window.gapi.load('auth2,signin2', function () {
                    var auth2 = window.gapi.auth2.init();
                    auth2.then(function () {
                        // Current values
                        if(auth2.isSignedIn.get()) {
                            var currentUser = auth2.currentUser.get();

                            let email = String(currentUser.getBasicProfile().getEmail());
                            resolve(email.endsWith('ignw.io'))
                        }
                        else
                            resolve(false);
                    });
                });
            });
        }
        let x = await wrapCheck();
        console.log(x);
        return x;
    }

    useEffect(() => {
        checkProfile()
            .then(status => setLoggedIn(status))
            .catch(err => {
                console.error(err);
                setLoggedIn(false);
            })
    });

    async function checkCustomer(e) {
        let resp = await getCopperInfo(e, "checkName");
        if (resp === false) {
            setCopperError("Customer not found in Copper");
            setAboutCustomer(null);
            return new Promise((resolve, reject) => {
                resolve("Customer not found in Copper");
            })
        } else {
            if (resp) {
                setCopperError(null);
                if (resp["customer_background"])
                    setAboutCustomer(resp["customer_background"]);
                else
                    setAboutCustomer("No about customer section in copper");
            }
        }
    }

    return (
        <Grid container spacing={2} lg={10} md={10} sm={10}>
            <Backdrop
                className={classes.backdrop}
                open={waiting}
                onClick={() => {
                    setWaiting(false);
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid>

            <NavBar/>
            <Paper style={{padding:40, }}>
                    <Grid>
                        <div className="g-signin2" data-onsuccess="onSignIn"> {/*add sign in check here once g button works*/}
                        </div>

                    </Grid>
                    {(loggedIn) ? (
                            <div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid item xs={6}>
                                        <TextField
                                            name={'customer'}
                                            error={!!errors.customer}
                                            helperText={(errors.customer) ? 'Customer is required' : null}
                                            inputRef={register({required: true})}
                                            label="Customer"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={(evt) => {
                                                setCustomer(evt.target.value);
                                                checkCustomer(evt.target.value);
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <FormControl fullWidth>
                                        <InputLabel>Template</InputLabel>

                                        <Select
                                            name={'template'}
                                            value={template}
                                            onChange={(evt) => {
                                                setTemplate(evt.target.value)
                                            }}
                                            labelWidth={500}
                                            styles={customStyles}
                                            fullWidth={true}
                                        >
                                            <MenuItem value="tandm_sow_template">SOW - T&M</MenuItem>
                                            <MenuItem value="fixed_sow_template">SOW - Fixed</MenuItem>
                                            <MenuItem value="bot_sow_template">SOW - Bucket of Time</MenuItem>
                                            <MenuItem value="aci_supercharger_dsi">aci_supercharger_dsi</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {((template === "bot_sow_template") ?
                                            <div>
                                                <Button onClick={newCostingSheet} variant="contained" style={{margin:20}}>sheets</Button>
                                            </div>
                                            :
                                            <div>

                                            </div>
                                    )}
                                </form>
                            </div>
                        ) : (
                            <div>
                                <Typography> sign in with an IGNW account</Typography>
                            </div>
                    )}
                </Paper>
            </Grid>
        </Grid>
    )
};

export default BotForm;

async function componentDidMount() {
    // 1. Load the JavaScript client library.
    async function wrapLoad() {
        return new Promise((resolve, reject) => {
            window.gapi.load("client", () => {
                resolve(initClient());
            });
        });
    }
    return await wrapLoad();
}

async function initClient () {
    function wrapInit() {
        return new Promise((resolve, reject) => {
            //2. Initialize the JavaScript client library.
            window.gapi.client.init({
                apiKey: config.apiKey,
                clientId: config.clientId,
                scope: config.scopes,
                // Your API key will be automatically added to the Discovery Document URLs.
                discoveryDocs: config.discoveryDocs
            })
                .then(() => {
                    // 3. Initialize and make the API request.
                    //resolve(build_sow()); //this should be the one
                });
            resolve(true);
        })
    }
    return await wrapInit();
}

async function copySheet(customer, template) {
    async function wrapDriveInit() {

        return new Promise((resolve, reject) => {
            window.gapi.client.load("drive", "v3", async () => {

                async function wrapCopy() {
                    let sheets_copy_title = customer + ' ' + template + ' costing worksheet';
                    let copy_body = {
                        "name": sheets_copy_title,
                        "parents": ["1i2dtbIUX-NonzrNrnDMqSBbuhvdMpGJP"],
                    };
                    let request = window.gapi.client.drive.files.copy({
                        "fileId": '1Z3IcxadWGi8E1I3Zu4L40Ah26CaT3QT3sS6z-hCrw5M',
                        "supportsAllDrives": true,
                        "resource": copy_body
                    });
                    function wrapSheetsCopyRequest() {
                        return new Promise((resolve, reject) => {
                            request.execute((resp) => {
                                let sheets_copy_id = resp.id;
                                resolve(sheets_copy_id);
                            });
                        })
                    }
                    let return_link = await wrapSheetsCopyRequest(request);
                    resolve(return_link);
                }
                await wrapCopy();
            });
        })
    }

    return await wrapDriveInit();
}
